const companiesSelect = document.querySelector('#companiesCats');
const allCompanies = document.querySelectorAll('.company');

if (companiesSelect) {
  companiesSelect.addEventListener('change', () => {
    if (companiesSelect.value !== '') {
      const currentCompanies = document.querySelectorAll(`.${companiesSelect.value}`);
      allCompanies.forEach(company => {
        company.style.display = 'none';
      });

      currentCompanies.forEach(currentCompany => {
        currentCompany.style.display = 'flex';
      });
    }
  });
  // const btn = document.querySelector('.companies__button');
  // btn.addEventListener('click', () => {
  //   if (companiesSelect.value !== '') {
  //     const currentCompanies = document.querySelectorAll(`.${companiesSelect.value}`);
  //     allCompanies.forEach(company => {
  //       company.style.display = 'none';
  //     });

  //     currentCompanies.forEach(currentCompany => {
  //       currentCompany.style.display = 'flex';
  //     });
  //   }
  // });
}
