// https://github.com/kenwheeler/slick

import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';

/****** SLIDERS *******/
const sliders = {
  home: $('.slides-container')
};

sliders.home.slick({
  arrows: false,
  autoplay: true,
  dots: true,
  autoplaySpeed: 5000
});
