import Cookies from 'js-cookie';

export default class WCAGService {
  constructor() {
    this.highContranstBtn = document.querySelector('.highcontrast-btn');
    this.increaseTextBtn = document.querySelector('.increase-text-size');
    this.decreaseTextBtn = document.querySelector('.decrease-text-size');
    this.textSizes = ['font-size-normal', 'font-size-medium', 'font-size-big'];
    this.currentTextSize = 0;
    this.prevTextSize = 0;

    this.checkCookies();
    this.setEventListeners();
  }

  checkCookies() {
    const currentHighContrastState = Cookies.get('highcontrast');
    if (currentHighContrastState == 'true') {
      document.documentElement.classList.add('highcontrast');
    }

    const currentTextSize = Cookies.get('textsize');
    if (currentTextSize) {
      this.currentTextSize = currentTextSize;
    }
    document.documentElement.classList.add(this.textSizes[this.currentTextSize]);
  }

  setEventListeners() {
    document.onkeydown = e => {
      if (e.altKey && e.which == 49) {
        window.location.href = '/';
      }

      if (e.altKey && e.which == 50) {
        this.handleHighContrastState();
      }

      if (e.altKey && e.which == 51) {
        this.handleTextSize('increase');
      }

      if (e.altKey && e.which == 52) {
        this.handleTextSize('decrease');
      }

      if (e.altKey && e.which == 53) {
        document.querySelector('#searchform input').focus();
      }
    };

    this.highContranstBtn.addEventListener('click', e => {
      e.preventDefault();
      this.handleHighContrastState();
    });

    this.increaseTextBtn.addEventListener('click', e => {
      e.preventDefault();
      this.handleTextSize('increase');
    });

    this.decreaseTextBtn.addEventListener('click', e => {
      e.preventDefault();
      this.handleTextSize('decrease');
    });
  }

  handleHighContrastState() {
    document.documentElement.classList.toggle('highcontrast');
    if (document.documentElement.classList.contains('highcontrast')) {
      Cookies.set('highcontrast', 'true', { expires: 365, path: '/' });
    } else {
      Cookies.set('highcontrast', 'false', { expires: 365, path: '/' });
    }
  }

  handleTextSize(action) {
    if (action == 'increase') {
      if (this.currentTextSize < 2) {
        this.prevTextSize = this.currentTextSize;
        this.currentTextSize++;
      }
    } else {
      if (this.currentTextSize > 0) {
        this.prevTextSize = this.currentTextSize;
        this.currentTextSize--;
      }
    }

    this.handleTextSizeState();
    this.updateTextSizeCookie(this.currentTextSize);
  }

  handleTextSizeState() {
    document.documentElement.classList.remove(this.textSizes[this.prevTextSize]);
    document.documentElement.classList.add(this.textSizes[this.currentTextSize]);
  }

  updateTextSizeCookie(textSize) {
    Cookies.set('textsize', textSize, { expires: 365, path: '/' });
  }
}
