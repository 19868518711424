import { isMobile, getScreenWidth } from './helper';
import SiteHeader from './siteHeader';
import { mergeMenus, toggleMenu, addSticky, closeMenu } from './navigation';
import FloatLabels from './forms/float-labels';
import CalendarService from './calendarService';
import WCAGService from './wcagService';

window.addEventListener('load', () => {
  new SiteHeader();
  new FloatLabels();
  new WCAGService();

  const calendar = document.querySelector('#calendar');
  if (calendar) {
    new CalendarService(calendar);
  }

  toggleMenu();

  if (getScreenWidth() < 1120) {
    mergeMenus();
  }

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
