//js
require('babel-polyfill');
require('./js/slider');
require('./js/scroll');
require('./js/helper');
require('./js/navigation');
require('./js/maps');
require('./js/main');
require('./js/companiesFilter');

//fonts
// require('./fonts/font/stylesheet.css');

// css
import 'normalize.css';
require('./sass/style.scss');
