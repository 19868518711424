export default class CalendarService {
  constructor(calendar) {
    this.calendarContainer = document.querySelector('.calendar-wrapper');
    this.popupContent = null;
    this.popupContainer = null;
    this.parentElement = null;
    this.selectedDate = null;
    this.init();
  }

  init() {
    this.setBtnsEventListeners();
  }

  setBtnsEventListeners() {
    const changeMonthBtns = document.querySelectorAll(".change-month");
    changeMonthBtns.forEach(button => {
      button.addEventListener('click', e => {
        e.preventDefault();
        const month = e.target.dataset.month;
        const year = e.target.dataset.year;
        this.getNewCalendar(month, year);
      })
    })

    const calendarDays = calendar.querySelectorAll('.popup');
    calendarDays.forEach(day => {
      day.addEventListener('click', e => {
        e.preventDefault();
        day.classList.add('selected');
        this.selectedDate = day.getAttribute('id');
        this.getEvents();
      });
    });
  }

  async getNewCalendar(month, year) {
    const fd = new FormData();
    fd.append('action', 'getNewCalendar');
    fd.append('month', month);
    fd.append('year', year);
    const req = await fetch(jsData.ajaxUrl, {
      method: 'POST',
      body: fd
    })
    .then(res => res.json())
    .then(res => {
      this.calendarContainer.innerHTML = res;
      this.setBtnsEventListeners();
    })
  }

  async getEvents() {
    const fd = new FormData();
    fd.append('date', this.selectedDate);
    fd.append('action', 'getEventsByDate');
    const req = await fetch(jsData.ajaxUrl, {
      method: 'POST',
      body: fd
    })
    .then(res => res.json())
    .then(res => {
      this.popupContent = res;
      this.buildPopup();
      this.openPopup();
    });
  }

  buildPopup() {
    this.popupContainer = document.createElement('div');
    this.popupContainer.classList.add('calendar-popup-container');
    this.popupContainer.innerHTML = this.popupContent;
  }
  
  openPopup() {
    this.parentElement = document.querySelector('.selected');
    document.body.appendChild(this.popupContainer);
    
    this.createCloseButton();
  }

  createCloseButton() {
    const closeButton = document.querySelector(".close-popup-button");
    closeButton.addEventListener('click', e => {
      e.preventDefault();
      this.parentElement.classList.remove('selected');
      document.body.removeChild(this.popupContainer);
    });
  }
}
